import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { serversInfo } from "../http/ServerAPI";
import {LOG_ROUTE, SERVER_DETAILS} from "../utils/consts";

// Function for declension of the word "игрок"
const getPlayersLabel = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return `${count} игрок`;
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return `${count} игрока`;
    return `${count} игроков`;
};

const Home = observer(() => {
    const { user } = useContext(Context);
    const [servers, setServers] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 600);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const fetchServers = async () => {
        try {
            const data = await serversInfo();
            setServers(data);
        } catch (error) {
            console.error("Failed to fetch server data:", error);
        }
    };

    useEffect(() => {
        fetchServers();
        const intervalId = setInterval(fetchServers, 60000);
        return () => clearInterval(intervalId);
    }, []);

    const handleOpen = (serverId) => {
        navigate(SERVER_DETAILS + '/' + serverId);
    };

    return (
        <Container maxWidth="xl">
            <h1>Список серверов</h1>
            <Grid container spacing={4}>
                {servers.map((server) => (
                    <Grid item xs={12} sm={6} md={3} key={server.id}>
                        <Card
                            onClick={() => handleOpen(server.id)}
                            sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', height: '100%' }}
                        >
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                <img
                                    src={server.img}
                                    alt={`${server.name} logo`}
                                    style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "cover",
                                        borderRadius: 8
                                    }}
                                />
                                <Typography variant="h6" component="div" gutterBottom>
                                    {server.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {server.description.length > 70
                                        ? `${server.description.substring(0, 70)}...`
                                        : server.description}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary"
                                            sx={{
                                                mt: 1,
                                                color: server.status === 3 ? "#89b6a1" : "#b98b97"
                                            }}
                                >
                                    {server.status === 3 ? 'Онлайн' : 'Оффлайн'}: {getPlayersLabel(server.online)}
                                </Typography>
                            </CardContent>
                            {!isMobile && (
                                <CardActions sx={{ m: 1 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        href={`steam://run/252490//+connect ${server.ip}:${server.port}`}
                                        target="_blank"
                                        title="Подключиться к серверу"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        Играть
                                    </Button>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
});

export default Home;
