import React, { useContext, useEffect, useRef, useState } from "react";
import { login } from "../http/userAPI";
import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../utils/consts";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CSSTransition } from "react-transition-group";
import * as THREE from "three";
import './Login.css';

const Login = observer(() => {
    const { user } = useContext(Context);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showForm, setShowForm] = useState(false);
    const formRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        setTimeout(() => setShowForm(true), 50); // Включаем форму с задержкой для анимации появления
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!isMobile && formRef.current && !formRef.current.contains(e.target)) {
                setShowForm(false);
                setTimeout(() => navigate(-1), 300);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, [isMobile, navigate]);

    const click = async () => {
        try {
            const data = await login(username, password);
            user.setUser(data);
            navigate(HOME_ROUTE);
        } catch (e) {
            console.log(e.response.data.message);
        }
    };

    const goBack = () => {
        setShowForm(false);
        setTimeout(() => navigate(-1), 300);
    }

    // 3D canvas animation setup
    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        camera.position.z = 5;

        const createCircleTexture = (color) => {
            const size = 64; // Size of the texture
            const canvas = document.createElement("canvas");
            canvas.width = size;
            canvas.height = size;
            const ctx = canvas.getContext("2d");

            // Draw a circle with the given color
            ctx.fillStyle = color.getStyle(); // Apply the color
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();

            return new THREE.CanvasTexture(canvas);
        };

        // Generate random colors for stars
        const getRandomColor = () => {
            const r = THREE.MathUtils.randFloat(0.2, 1); // Red component
            const g = THREE.MathUtils.randFloat(0.1, 1); // Green component
            const b = THREE.MathUtils.randFloat(1, 1); // Blue component (maxed out for blue stars)
            return new THREE.Color(r, g, b);
        };

        const starGeometry = new THREE.BufferGeometry();
        const starVertices = [];
        const starColors = [];

        for (let i = 0; i < 20; i++) {
            const x = THREE.MathUtils.randFloatSpread(200);
            const y = THREE.MathUtils.randFloatSpread(2);
            const z = THREE.MathUtils.randFloatSpread(200);
            starVertices.push(x, y, z);

            const color = getRandomColor();
            starColors.push(color.r, color.g, color.b);

            // Create texture for each star based on its color
            const texture = createCircleTexture(color);

            // Create material with unique color and texture for each star
            const starMaterial = new THREE.PointsMaterial({
                color: color, // Apply the random color
                size: 0.7,
                sizeAttenuation: true,
                transparent: true,
                opacity: 0.7,
                blending: THREE.AdditiveBlending,
                map: texture, // Apply the texture
            });

            // Create geometry for each star
            const star = new THREE.Points(starGeometry, starMaterial);
            star.position.set(x, y, z); // Set each star's position

            scene.add(star); // Add each star to the scene
        }

        // Set the attributes for the geometry (this is applied globally, but not to individual stars)
        starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
        starGeometry.setAttribute('color', new THREE.Float32BufferAttribute(starColors, 3));

        let mouseX = 0;
        let mouseY = 0;
        const handleMouseMove = (event) => {
            mouseX = (event.clientX / window.innerWidth) * 2 - 1;
            mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
        };
        document.addEventListener("mousemove", handleMouseMove);

        const animate = () => {
            scene.children.forEach((star) => {
                star.rotation.x += 0.0006;
                star.rotation.y += 0.0006;
            });

            camera.position.x += (mouseX * 0.3 - camera.position.x) * 0.04;
            camera.position.y += (mouseY * 0.3 - camera.position.y) * 0.04;
            camera.lookAt(scene.position);

            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: isMobile ? 'background.default' : 'rgba(0, 0, 0, 1)',
                zIndex: 1300, // Ensure it overlays other elements
            }}
        >
            {/* 3D Canvas for background */}
            <canvas ref={canvasRef} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
            }}></canvas>

            <CSSTransition in={showForm} timeout={300} classNames="fade" unmountOnExit>
                <Box
                    ref={formRef}
                    sx={{
                        width: isMobile ? '100%' : 400,
                        bgcolor: 'background.paper',
                        p: 5,
                        borderRadius: 2,
                        boxShadow: isMobile ? 'none' : 6,
                        position: 'relative',
                        zIndex: 2,
                    }}
                >
                    <IconButton onClick={goBack} sx={{ position: 'absolute', top: 36, right: 32 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" gutterBottom>
                        Авторизация
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Введите Логин..."
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        margin="normal"
                        sx={{
                            "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 100px #474f47 inset",  // цвет автозаполнения
                                WebkitTextFillColor: "#ffffff",  // цвет текста
                            },
                            "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus": {
                                WebkitBoxShadow: "0 0 0 100px #474f47 inset",
                                WebkitTextFillColor: "#ffffff",
                            },
                        }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Введите Пароль..."
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        sx={{
                            "& input:-webkit-autofill": {
                                WebkitBoxShadow: "0 0 0 100px #474f47 inset",  // цвет автозаполнения
                                WebkitTextFillColor: "#ffffff",  // цвет текста
                            },
                            "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus": {
                                WebkitBoxShadow: "0 0 0 100px #474f47 inset",
                                WebkitTextFillColor: "#ffffff",
                            },
                        }}
                    />
                    <Button fullWidth variant="contained" color="primary" onClick={click} sx={{ mt: 2 }}>
                        Войти
                    </Button>
                </Box>
            </CSSTransition>
        </Box>
    );
});

export default Login;
