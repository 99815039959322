import {
    HOME_ROUTE,
    LOGIN_ROUTE,
    REGISTRATION_ROUTE,
    PLUGINS_ROUTE, SERVERS_ROUTE, SERVER_PLUGINS_ROUTE, CREATE_SERVER, CHAT_ROUTE, LOG_ROUTE, SERVER_DETAILS
} from "./utils/consts";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Plugins from "./pages/Plugins";
import Servers from "./pages/Servers";
import ServerPlugins from "./pages/ServerPlugins";
import CreateServer from "./pages/server/CreateServer";
import Chat from "./pages/Chat";
import Log from "./pages/Log";
import ServerDetails from "./pages/ServerDetails";

export const authRoutes = [
    {
        path: PLUGINS_ROUTE,
        Component: Plugins
    },
    {
        path: SERVERS_ROUTE,
        Component: Servers
    },
    {
        path: SERVER_PLUGINS_ROUTE + '/:id',
        Component: ServerPlugins
    },
    {
        path: CHAT_ROUTE + '/:server_id',
        Component: Chat
    },
    {
        path: LOG_ROUTE + '/:server_id',
        Component: Log
    },
    {
        path: CREATE_SERVER,
        Component: CreateServer
    }
];

export const publicRoutes = [
    {
        path: HOME_ROUTE,
        Component: Home
    },
    {
        path: LOGIN_ROUTE,
        Component: Login
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Registration
    },
    {
        path: SERVER_DETAILS + '/:server_id',
        Component: ServerDetails
    }
];