import {makeAutoObservable } from "mobx";

export default class UserStore {
    constructor () {
        this._isAuth = false;
        this._user = {};
        this._userName = '';
        this._role = '';
        this._users = {};
        this._chat = {}
        this._log = {}
        this._servers = {}
        this._player_info = {}
        this._player_count = {}
        makeAutoObservable(this);
    }

    setIsAuth(bool){
        this._isAuth = bool;
    }

    setUser(user){
        this._user = user;
    }

    setUsers(users){
        this._users = users;
    }

    setChat(storage) {
        const { chat, data } = storage; // Получаем chat и data из storage

        if (!this._chat[chat]) {
            // Если чата с таким ID еще нет, создаем его
            this._chat[chat] = {
                chat,
                data: []
            };
        }

        // Добавляем новые данные для данного чата
        if (data) {
            this._chat[chat].data.push(...data); // Добавляем массив данных для чата
            this._chat = { ...this._chat };
        }
    }

    setLog(storage) {
        const { log, data } = storage; // Получаем chat и data из storage

        if (!this._log[log]) {
            // Если чата с таким ID еще нет, создаем его
            this._log[log] = {
                log,
                data: []
            };
        }

        // Добавляем новые данные для данного чата
        if (data) {
            this._log[log].data.push(...data); // Добавляем массив данных для чата
            this._log = { ...this._log };
        }
    }

    setPlayerInfo(storage) {
        const { player_info, data } = storage;
        if (!this._player_info[player_info]) {
            // Если чата с таким ID еще нет, создаем его
            this._player_info[player_info] = {
                player_info,
                data: []
            };
        }

        if (!this._player_count[player_info]) {
            // Если чата с таким ID еще нет, создаем его
            this._player_count[player_info] = {
                player_info,
                data: []
            };
        }

        // Добавляем новые данные для данного чата
        if (data) {
            this._player_info[player_info].data.push(...data); // Добавляем массив данных для чата
            this._player_info = { ...this._player_info };
            this._player_count[player_info].data = data; // Добавляем массив данных для чата
            this._player_count = { ...this._player_count };
        }
    }

    setRole(role){
        this._role = role;
    }

    setUserName(userName){
        this._userName = userName;
    }

    setServers(servers) {
        this._servers = servers;
    }

    get user(){
        return this._user;
    }

    get users(){
        return this._users;
    }

    getStorageByChatId(chatId) {
        return this._chat[chatId] || { data: [] };
    }

    getStorageByLogId(logId) {
        return this._log[logId] || { data: [] };
    }

    getStorageByPlayerInfoId(playerInfoId) {
        return this._player_info[playerInfoId] || { data: [] };
    }

    get chat(){
        return this._chat;
    }

    get log(){
        return this._log;
    }

    get player_info(){
        return this._player_info;
    }

    get player_count(){
        return this._player_count;
    }

    get role(){
        return this._role;
    }

    get userName(){
        return this._userName;
    }
    get isAuth(){
        return this._isAuth;
    }

    get servers(){
        return this._servers;
    }
}