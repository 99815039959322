import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { serverInfo } from "../http/ServerAPI";
import BreadCrumbs from "../components/Breadcrumbs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ForumIcon from "@mui/icons-material/Forum";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Context } from "../index";
import CircularProgress from "@mui/material/CircularProgress";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Area,
    ComposedChart, Legend
} from 'recharts';

const getPlayersLabel = (count) => {
    if (count % 10 === 1 && count % 100 !== 11) return `${count} игрок`;
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return `${count} игрока`;
    return `${count} игроков`;
};

const ServerDetails = () => {
    const { user } = useContext(Context);
    const navigate = useNavigate();
    const { server_id } = useParams();
    const [server, setServer] = useState(null);
    const [isAuth, setIsAuth] = useState(false);
    const isMobile = useMediaQuery("(max-width:600px)");
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        if (user.isAuth) setIsAuth(true);
    }, [user.isAuth]);

    useEffect(() => {
        const fetchServerDetails = async () => {
            try {
                const data = await serverInfo(server_id);
                setServer(data);
            } catch (error) {
                console.error("Failed to fetch server details:", error);
            }
        };
        fetchServerDetails();
    }, [server_id]);

    const handleCopyToClipboard = () => {
        if (server) {
            navigator.clipboard.writeText(`${server.ip}:${server.port}`);
            setCopySuccess(true);
        }
    };

    const handleCloseSnackbar = () => {
        setCopySuccess(false);
    };

    if (!server) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box sx={{ width: '100%', marginTop: 3 }}>
                <BreadCrumbs hrefs='' name={server.name} />
            </Box>
            <Box sx={{ width: '100%', marginTop: 3 }}>
                <Paper sx={{ padding: 3, mb: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'center' : 'flex-start',
                            textAlign: 'left',
                        }}
                    >
                        <Box
                            component="img"
                            src={server.img}
                            alt={`${server.name} logo`}
                            sx={{
                                width: isMobile ? '100%' : 200,
                                height: isMobile ? 'auto' : 200,
                                objectFit: isMobile ? "contain" : "cover",
                                borderRadius: 2,
                                marginBottom: isMobile ? 2 : 0,
                                marginRight: isMobile ? 0 : 3,
                                alignSelf: 'center',
                            }}
                        />
                        <Box>
                            <Typography variant="h6" component="h2" gutterBottom>
                                {server.name}
                            </Typography>
                            <Typography variant="subtitle2" color="text.secondary"
                                        sx={{
                                            mt: 1,
                                            color: server.status === 3 ? "#89b6a1" : "#b98b97"
                                        }}
                            >
                                {server.status === 3 ? 'Онлайн' : 'Оффлайн'}: {getPlayersLabel(server.online)}
                            </Typography>
                            {!isMobile && (
                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        href={`steam://run/252490//+connect ${server.ip}:${server.port}`}
                                        target="_blank"
                                        title="Подключиться к серверу"
                                    >
                                        Играть
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleCopyToClipboard}
                                        title="Скопировать IP и порт"
                                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                    >
                                        {`${server.ip}:${server.port}`}
                                        <ContentCopyIcon fontSize="small" />
                                    </Button>
                                    {isAuth && (
                                        <IconButton>
                                            <ForumIcon
                                                color="primary"
                                                fontSize="large"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => navigate(`/chat/${server.id}`)}
                                            />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                            <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2 }}>
                                Добавлен: {server.created}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="text.secondary" paragraph>
                            {server.description.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Посещаемость сервера за 24 часа
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart  data={server.player_online}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tickFormatter={(date) => date.split(" ")[1]} />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name) => {
                                        if (name === "players") return [`${value}`, "Игроков"];
                                        if (name === "max_players") return [`${value}`, "Максимум игроков"];
                                        return value;
                                    }}
                                />
                                <Legend />
                                <Area
                                    type="monotone"
                                    dataKey="players"
                                    stroke="#8884d8"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                    tooltipType="none"
                                    legendType="none"
                                />
                                <Line
                                    type="monotone"
                                    dataKey="players"
                                    name="Игроков"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="max_players"
                                    name="Максимум игроков"
                                    stroke="#82ca9d"
                                    strokeDasharray="5 5"
                                    dot={false}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Box>
                </Paper>
            </Box>
            <Snackbar
                open={copySuccess}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    IP и порт скопированы в буфер обмена!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default ServerDetails;
